import { FaStar } from "react-icons/fa";
function Hero() {
  return (
    <section id="hero" className="bg-white py-8 pt-[120px]">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold mb-4">
            الخبرة العراقية للتعليم والموارد
          </h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            سواء كنت طالباً في التخصصات الطبية تسعى لتطوير مهاراتك أو مريضاً
            يحتاج إلى استشارة سريعة، تطبيق إيثر يوفر لك كل ما تحتاجه. استمتع
            بتجربة تدريب متقدمة. تعلم من أفضل الأطباء واحجز مواعيدك مع أطباء
            متخصصين بكل سهولة.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-6">
          <div className=" rounded-md p-6 relative">
            <img
              className="rounded-[50px] shadow-md"
              src="/img (1).png"
              alt="Placeholder"
            />
            <div className="hidden w-[200px] h-[95px] lg:flex flex-col justify-center items-center gap-1 font-bold lg:absolute top-12 -left-16 bg-white rounded-2xl  px-5 py-4  shadow-xl">
              <p className="flex  items-center gap-2 text-sm text-gray-400">
                تدريب الصيفي <FaStar size={20} className="text-yellow-400" />
              </p>
              <p className="text-xs text-center w-[90%]">
                قريباً، سيكون لديكم ما يسهل طريقكم نحو النجاح
              </p>
            </div>
            <div className="bg-white lg:flex flex-col gap-2 hidden w-[166px] h-[166px] text-[10px] font-medium lg:absolute bottom-0 -right-8 rounded-lg py-7 px-5 shadow-xl">
              <div className="flex flex-row-reverse gap-1">
                <img
                  src="avatar.png"
                  className="w-8 aspect-square rounded-full"
                  alt=""
                />
                <p className="flex flex-col text-right font-bold">
                  د.امير فاضل
                  <span className="font-medium text-[10px]">
                    مدير التنفيذي لأيثر
                  </span>
                </p>
              </div>
              <p className="text-right">
                هل أنت مستعد لتجربة ثورة في عالم الطب والرعاية الصحية في العراق؟
                استعد لتطبيق Ether
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
