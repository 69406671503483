import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

function Footer() {
  return (
    <footer className="px-6 lg:px-24 2xl:px-48">
      <div className="bg-accent-600 rounded-t-3xl py-6 px-6 lg:px-16 lg:py-10 flex flex-col gap-4 ">
        <div className="flex flex-col lg:flex-row-reverse lg:justify-between items-center gap-8">
          <div className="flex flex-row items-center text-white">
            <img src="/icon.png" alt="" />
            <p className="font-bold">
              Ether <span className="font-normal">Iraq</span>
            </p>
          </div>
          <div>
            <ul className="text-white flex flex-row-reverse text-center items-center gap-4 ">
              <li className="text-center">
                <a href="#hero">الصفحة الرئيسية</a>
              </li>
              <li className="text-center">
                <a href="#about">عن التطبيق</a>
              </li>
              <li className="text-center">
                <a href="#contact">تواصل</a>
              </li>
            </ul>
          </div>
          <div className="">
            <ul className="text-white flex flex-row-reverse gap-4 text-2xl">
              <li>
                <a href="https://www.facebook.com/EtherIraq" target="_blank">
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/etheriraq/" target="_blank">
                  <FaInstagram />
                </a>
              </li>

              <li>
                <a
                  href="https://www.linkedin.com/in/dramir0078"
                  target="_blank"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a href="mailto:info@etheriq.org">
                  <FaGoogle />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="bg-white w-full" />
        <div className="text-center text-white flex flex-col lg:flex-row-reverse lg:gap-8 lg:text-right">
          <p className="">
            <span>&copy; {new Date().getFullYear()} EtherIraq. </span>جميع
            الحقوق محفوظة
          </p>
          <a className="underline text-white " href="">
            سياسة الخصوصية
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
