import { FaBook, FaHospitalSymbol } from "react-icons/fa";
import { RiShieldUserFill } from "react-icons/ri";
import globe from "../assets/globe.svg";
function Features() {
  return (
    <section
      id="about"
      className="bg-white pt-[120px] py-12 w-full overflow-hidden 2xl:px-40"
    >
      <div className=" flex flex-col lg:flex-row items-center justify-between gap-6 px-4">
        <div className=" w-full md:w-[60%] lg:w-[35%]">
          <img src={globe} alt="globe" />
        </div>
        <div className="flex-1 max-w-lg text-right">
          <h2 className="text-4xl font-bold mb-4 px-6">مميزات تطبيق إيثر</h2>
          <p className="text-gray-600 text-xl mb-8 px-6">
            تطبيق إيثر يقدم مجموعة من الخدمات المتكاملة التي تلبي احتياجات
            الطلاب والمرضى على حد سواء.
          </p>
          <div className="flex flex-col gap-6">
            <div className="flex flex-row-reverse items-start gap-3 hover:shadow-2xl duration-300 ease-in py-4 rounded-md px-6">
              <div className="rounded-full text-accent-700 ">
                <FaHospitalSymbol size={45} />
              </div>
              <div>
                <h3 className="text-xl font-semibold">تدريب طبي متكامل</h3>
                <p className="text-gray-600 text-sm">
                  يوفر إيثر استمارات وتوجيهات للتدريب الصيفي، مما يساعد الطلاب
                  في الحصول على خبرة عملية مميزة في المجال الطبي.
                </p>
              </div>
            </div>
            <div className="flex flex-row-reverse items-start gap-3 hover:shadow-2xl duration-300 ease-in py-4 rounded-md px-6">
              <div className=" rounded-full text-accent-700 ">
                <FaBook size={45} />
              </div>
              <div>
                <h3 className="text-xl font-semibold">منصة تعليمية شاملة</h3>
                <p className="text-gray-600 text-sm">
                  يحتوي التطبيق على موارد تعليمية متطورة مصممة خصيصًا لطلاب
                  التخصصات الطبية، بما في ذلك محاضرات، دورات، واختبارات تقييمية.
                </p>
              </div>
            </div>
            <div className="flex flex-row-reverse items-start gap-3 hover:shadow-2xl duration-300 ease-in py-4 rounded-md px-6">
              <div className="rounded-full text-accent-700 ">
                <RiShieldUserFill size={45} />
              </div>
              <div>
                <h3 className="text-xl font-semibold">تجربة مستخدم سلسة</h3>
                <p className="text-gray-600 text-sm">
                  يتميز إيثر بواجهة مستخدم سهلة الاستخدام ومرنة، مما يجعل التنقل
                  بين الخدمات المختلفة سريعًا وبسيطًا.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
