import { useState } from "react";
import spiral from "../assets/spiral.svg";
function ContactForm() {
  const [name, setName] = useState("");

  const [message, setMessage] = useState("");

  const handleMailTo = () => {
    const mailtoLink = `mailto:info@etheriq.org?subject=From:%20${encodeURIComponent(
      name
    )}&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <section
      id="contact"
      className="pt-[120px] pb-40 px-6 lg:px-24 2xl:px-48 text-right "
    >
      <div className="flex flex-col lg:flex-row-reverse items-end gap-4 pb-12">
        <h3>
          <span className="text-3xl font-medium px-2 py-2 text-white bg-accent-600 rounded-lg ">
            اتصل بنا
          </span>
        </h3>
        <p className="text-lg  line-clamp-6">
          تواصل معنا: دعنا نناقش احتياجاتك في الخدمات الطبية والتعليمية.
        </p>
      </div>
      <div className="flex flex-row-reverse justify-between rounded-2xl py-20 px-4 lg:pl-0 lg:pr-20 shadow-[-4px_3px_0_3px] shadow-accent-400">
        <form className="flex flex-col gap-4 formContainer w-full" action="">
          <div className="flex flex-col text-right">
            <label htmlFor="name">الاسم</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="الاسم"
              className="text-right"
            />
          </div>

          <div className="flex flex-col text-right">
            <label htmlFor="message">الرسالة</label>
            <textarea
              name="message"
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="رسالة"
              className="text-right resize-none"
            ></textarea>
          </div>
          <div className="flex justify-end">
            <a
              onClick={handleMailTo}
              className="bg-accent-600 text-white text-center text-xl px-8 py-3 rounded-lg cursor-pointer"
            >
              ارسل رسالة
            </a>
          </div>
        </form>
        <div className="hidden lg:block">
          <img src={spiral} alt="" />
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
