import { useState } from "react";
import icon from "../assets/icon.svg";
import { IoMenuOutline } from "react-icons/io5";

function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const handleMenu = () => {
    setShowMenu(!showMenu);
  };
  const handleContact = () => {
    const mailLinkto = `mailto:info@etheriq.org`;
    window.location.href = mailLinkto;
  };
  return (
    <header className="fixed bg-white z-[99999] top-0 left-0 right-0 ">
      <div className="flex flex-row-reverse items-center relative justify-between lg:justify-between px-6 pt-4 pb-2 lg:px-24 2xl:px-48 my-0 mx-auto lg:pt-8">
        <div className="font-quicksand font-bold flex flex-row-reverse gap-3">
          <p className="flex flex-col ">
            Ether<span className="font-normal">Iraq</span>
          </p>
          <img src={icon} alt="icon" />
        </div>
        <div className="">
          <IoMenuOutline
            className="lg:hidden"
            size={26}
            onClick={() => handleMenu()}
          />
          <div
            className={`absolute lg:static dropDown ${
              !showMenu ? "" : "dropDownActive"
            }`}
          >
            <ul
              className={`flex flex-col text-lg  gap-8 h-screen  bg-accent-600 text-white lg:text-black lg:bg-white pt-8 px-7 lg:p-0 lg:flex-row-reverse lg:text-sm lg:justify-center lg:items-center w-full lg:h-auto`}
            >
              <li>
                <ul className="flex flex-col  lg:flex-row-reverse gap-4">
                  <li>
                    <a onClick={() => setShowMenu(false)} href="#hero">
                      الصفحة الرئيسية
                    </a>
                  </li>
                  <li>
                    <a onClick={() => setShowMenu(false)} href="#about">
                      عن التطبيق
                    </a>
                  </li>
                  <li>
                    <a onClick={() => setShowMenu(false)} href="#contact">
                      تواصل
                    </a>
                  </li>
                </ul>
              </li>
              <li className="lg:hidden">
                <button
                  onClick={handleContact}
                  className="rounded-full border-white lg:border-black border px-6 py-2"
                >
                  اتصل بنا
                </button>
              </li>
            </ul>
          </div>
        </div>
        <button
          onClick={handleContact}
          className="rounded-full hidden  lg:block  lg:border-accent-600 text-accent-600 font-bold border px-10 py-2 hover:border-white hover:text-white hover:bg-accent-600 ease-in duration-500"
        >
          اتصل بنا
        </button>
      </div>
    </header>
  );
}

export default Header;
